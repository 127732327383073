
import React, { Component } from "react";
import "./App.css";
import Home from "./pages/Home";
import OTPVerify from "./pages/OTPVerify";
// import GameSpin from "./pages/GameSpin";
// import Address from "./pages/Address";
// import Success from "./pages/Success";
import {
  BrowserRouter as Router,
  Route,
  // Switch,
  // Link,
  // Redirect
} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/otp" component={OTPVerify} />
        {/* <Route exact path="/detail" component={Datail} />
        <Route exact path="/game" component={GameSpin} />
        <Route exact path="/address" component={Address} />
        <Route exact path="/success" component={Success} /> */}
      </Router>
    );
  }
}

export default App;
