import React, { Component, Fragment } from "react";
import Helper from "../Global/Helper";

// import "./styles.css";
import Service from "../services/home";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
// import ModalAlert from "../Components/ModalAlert";
import logo_head from "./images/logo.png";
import liffHelper from "../utils/liffHelper";
import messageHelper from "../utils/messagingApiHelper";
import exit from "./images/exit.png";
import { geolocated } from "react-geolocated";
const liff = window.liff;
class OTPVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: null,
      otp_number: "",
      disableOTPButton: true,
      isReQuestError: false,
      isVerifySuccess: false,
      isReQuestError_msg: "",
      isErrorCode: "",
      data: {},
      otp_code: "",
      ref_code: "",
      location: null,
      msg_error: "รหัส OTP ไม่ถูกต้อง",
      minutes: 0,
      seconds: 5,
    };
  }

  componentDidMount() {
    Helper.resetToTop();
    let data = this.loadState("customer");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);

        this.setState({
          location: position.coords.latitude + "," + position.coords.longitude,
        });
      },
      (error) => console.log(error)
    );

    this.setState(
      {
        data,
      },
      () => {
        this.requestOTP(data.mobile);
        console.log("data", data.mobile);
      }
    );
  }
  newRequestAgain = () => {
    const { mobile } = this.state;
    this.requestOTP(mobile);
  };

  loadState = (state) => {
    try {
      const serializedState = localStorage.getItem(state);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  // checkPromotionCode
  async requestOTP(mobile) {
    const { history } = this.props;
    let jwt = localStorage.getItem("jwt");
    try {
      const { status, msg, data } = await Service.requestOTP(jwt, mobile);
      console.log("status ====> ", status);
      console.log("msg ====> ", msg);
      console.log("data ====> ", data);
      if (status === "Success") {
        this.setState({
          ref_code: data.ref_code,
        });
      } else {
        this.setState({
          isReQuestError: true,
          isReQuestError_msg: msg,
        });
      }
    } catch (error) {
      console.log("DISCONNECT API");
      history.push("/");
    }
  }

  async insertDataCustomer() {
    const { history } = this.props;
    let jwt = localStorage.getItem("jwt");
    console.log("insertDataCustomer", jwt);
    const { data, location, otp_number, ref_code } = this.state;

    let first_name = data.first_name || "";
    let last_name = data.last_name || "";
    let phone_number = data.mobile || "";
    let email = "";
    let locations = location;
    let promotion_code = data.code;
    let master_campaign_id = "14";
    let obj_location_detail = "";
    let otp_code = otp_number;
    let otp_ref_code = ref_code;
    try {
      const { status, msg } = await Service.insertDataCustomer(
        jwt,
        first_name,
        last_name,
        phone_number,
        email,
        locations,
        promotion_code,
        master_campaign_id,
        obj_location_detail,
        otp_code,
        otp_ref_code
      );
      if (status === "Success") {
        console.log("send coupon");
         this.onVerifySuccess();
      } else {
         history.push("/");
        // this.onVerifySuccess();
        // this.setState({
        //   isErrorCode: "otperror",
        //   msg_error: msg,
        // });
      }
    } catch (error) {
      history.push("/");
      console.log(error);
    }
  }

  onVerifySuccess = () => {
    console.log("onVerifySuccess");
    this.setState(
      {
        isVerifySuccess: true,
      },
      () => {
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;

          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            clearInterval(this.myInterval);

            // this.sendPromotionA();
            // this.sendPromotionB();
            // this.sendPromotionC();
            // this.sendPolicy();
            // liff.closeWindow();
            console.log("Success Send Coupon");
          }
        }, 1000);
        setTimeout(() => {
          this.sendPromotionA();
        }, 1000);
        setTimeout(() => {
          this.sendPromotionB();
        }, 2000);
        setTimeout(() => {
          this.sendPromotionC();
        }, 3000);
        setTimeout(() => {
          liff.sendMessages([
            {
              type: "flex",
              altText: "Flex Message",
              contents: {
                type: "bubble",
                direction: "ltr",
                body: {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "เงื่อนไขการใช้คูปอง",
                      size: "md",
                      weight: "bold",
                    },
                    {
                      type: "separator",
                    },
                    {
                      type: "text",
                      text: "1. คูปองส่วนลดดอกเบี้ย 10%",
                      margin: "md",
                      size: "xxs",
                      align: "start",
                      weight: "bold",
                    },
                    {
                      type: "text",
                      text:
                        "   - สามารถใช้ได้เพียงครั้งเดียวเท่านั้น ใช้เป็นส่วนลด",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   ดอกเบี้ยเฉพาะที่สาขาเท่านั้น ",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text:
                        "   - สามารถใช้ได้ตั้งแต่วันที่ 16 มิถุนายน 2563 จนถึง",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   วันที่ 30 กันยายน 2563",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - ไม่สามารถแลกเปลี่ยนเป็นเงินสดได้",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - สามารถใช้ได้เฉพาะตั๋วจำนำที่จำนำตั้งแต่เดือน",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   มิถุนายน 2563 เป็นต้นไป",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   (เมื่อครบวันหมดอายุไม่สามารถใช้งานได้)",
                      margin: "sm",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "2. คูปองส่วนลดซื้อทองคำบาทละ 300 บาท",
                      margin: "sm",
                      size: "xxs",
                      align: "start",
                      weight: "bold",
                    },
                    {
                      type: "text",
                      text:
                        "   - ใช้เป็นส่วนลดทองคำสภาพใหม่ ณ โรงรับจำนำอีซี่มันนี่",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   ทุกสาขา",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - สามารถใช้ได้ถึงวันที่ 30 กันยายน 2563",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   (สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                      margin: "sm",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   ไม่สามารถใช้งานได้)",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "3. คูปองส่วนลดเครื่องประดับอัญมณี 10%",
                      margin: "sm",
                      size: "xxs",
                      align: "start",
                      weight: "bold",
                    },
                    {
                      type: "text",
                      text: "   - ใช้เป็นส่วนลดเครื่องประดับเพชร-พลอย",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   - สามารถใช้ได้ถึงวันที่ 30 กันยายน 2563",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   (สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                      margin: "sm",
                      size: "xxs",
                    },
                    {
                      type: "text",
                      text: "   ไม่สามารถใช้งานได้)",
                      margin: "none",
                      size: "xxs",
                    },
                    {
                      type: "image",
                      url: "https://2tm.easymoneydev.com/images/mascot.png",
                      size: "sm",
                    },
                  ],
                },
              },
            },
          ]);
        }, 4000);
        setTimeout(() => {
          liff.closeWindow();
        }, 6000);
      }
    );
  };
  verifyOTP = () => {
    console.log("verifyOTP");
    this.insertDataCustomer();
  };
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  sendPromotionA() {
    let message;
    let value = "https://2tm.easymoneydev.com/images/2TM_1.jpg";
    message = messageHelper.createImageMessage(value, value);
    if (message) {
      liffHelper
        .sendMessages([message])
        .then(() => {
          console.log("Success");
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }
  sendPromotionB() {
    let message;
    let value = "https://2tm.easymoneydev.com/images/2TM_2.jpg";
    message = messageHelper.createImageMessage(value, value);
    if (message) {
      liffHelper
        .sendMessages([message])
        .then(() => {
          console.log("Success");
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }
  sendPromotionC() {
    let message;
    let value = "https://2tm.easymoneydev.com/images/2TM_3.jpg";
    message = messageHelper.createImageMessage(value, value);
    if (message) {
      liffHelper
        .sendMessages([message])
        .then(() => {
          console.log("Success");
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }
  sendPolicy() {
    liff.sendMessages([
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          direction: "ltr",
          body: {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: "เงื่อนไขการใช้คูปอง",
                size: "md",
                weight: "bold",
              },
              {
                type: "separator",
              },
              {
                type: "text",
                text: "1. คูปองส่วนลดดอกเบี้ย 10%",
                margin: "md",
                size: "xxs",
                align: "start",
                weight: "bold",
              },
              {
                type: "text",
                text: "- สามารถใช้ได้เพียงครั้งเดียวเท่านั้น ใช้เป็นส่วนลด",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "ดอกเบี้ยเฉพาะที่สาขาเท่านั้น ",
                size: "xxs",
              },
              {
                type: "text",
                text: "- สามารถใช้ได้ตั้งแต่วันที่ 16 มิถุนายน 2563 จนถึง",
                size: "xxs",
              },
              {
                type: "text",
                text: "วันที่ 30 กันยายน 2563",
                size: "xxs",
              },
              {
                type: "text",
                text: "- ไม่สามารถแลกเปลี่ยนเป็นเงินสดได้",
                size: "xxs",
              },
              {
                type: "text",
                text: "- สามารถใช้ได้เฉพาะตั๋วจำนำที่จำนำตั้งแต่เดือน",
                size: "xxs",
              },
              {
                type: "text",
                text: "มิถุนายน 2563 เป็นต้นไป",
                size: "xxs",
              },
              {
                type: "text",
                text: "(เมื่อครบวันหมดอายุไม่สามารถใช้งานได้)",
                margin: "sm",
                size: "xxs",
              },
              {
                type: "text",
                text: "2. คูปองส่วนลดซื้อทองคำบาทละ 300 บาท",
                margin: "sm",
                size: "xxs",
                align: "start",
                weight: "bold",
              },
              {
                type: "text",
                text: "- ใช้เป็นส่วนลดทองคำสภาพใหม่ ณ โรงรับจำนำอีซี่มันนี่",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "ทุกสาขา",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "- ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "- สามารถใช้ได้ถึงวันที่ 30 กันยายน 2563",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "(สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                margin: "sm",
                size: "xxs",
              },
              {
                type: "text",
                text: "ไม่สามารถใช้งานได้)",
                size: "xxs",
              },
              {
                type: "text",
                text: "3. คูปองส่วนลดเครื่องประดับอัญมณี 10%",
                margin: "sm",
                size: "xxs",
                align: "start",
                weight: "bold",
              },
              {
                type: "text",
                text: "- ใช้เป็นส่วนลดเครื่องประดับเพชร-พลอย",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "- ใช้เป็นส่วนลดในการซื้อหน้าร้านและออนไลน์",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "- สามารถใช้ได้ถึงวันที่ 30 กันยายน 2563",
                margin: "none",
                size: "xxs",
              },
              {
                type: "text",
                text: "(สามารถใช้ได้มากกว่า 1 ครั้ง / เมื่อครบวันหมดอายุ",
                margin: "sm",
                size: "xxs",
              },
              {
                type: "text",
                text: "ไม่สามารถใช้งานได้)",
                margin: "none",
                size: "xxs",
              },
              {
                type: "image",
                url: "https://2tm.easymoneydev.com/images/mascot.png",
                size: "sm",
              },
            ],
          },
        },
      },
    ]);
  }
  
  render() {
    const {
      jwt,
      otp_number,
      disableOTPButton,
      isErrorCode,
      location,
      msg_error,
      isReQuestError,
      isReQuestError_msg,
      isVerifySuccess,
      minutes,
      seconds,
    } = this.state;
    return (
      <Fragment>
        <body>
          <header>
            <img src={logo_head} alt="" />
          </header>
          <section>
            {isVerifySuccess === true ? (
              <div class="samllpop ">
                <div class="samllpop_pop">
                  <h2 class="font55">ขอบคุณที่ใช้บริการ</h2>
                  <h3 class="font45">โรงรับจำนำอีซี่มันนี่</h3>
                  <span class="font45">
                    ระบบจำนำท่านกลับสู่หน้าแรกอัตโนมัติ{" "}
                    <i id="redirect">{seconds} </i>
                  </span>
                </div>
              </div>
            ) : null}

            {isReQuestError === true ? (
              <div className="samllpop">
                <div className="samllpop_pop">
                  <img  
                  src={exit} 
                  alt="" className="exit"
                  onClick={() => {
                      console.log("onExit");
                    }}
                   />
                  <h2 className="font55">ขออภัยค่ะ</h2>
                  <h3 className="font45">{isReQuestError_msg}</h3>
                </div>
              </div>
            ) : null}
            <div className={`otpform ${isErrorCode}`}>
              <div className="otpform_inner dataform">
                <h2>กรุณากรอกรหัสที่ได้รับ</h2>
                <span className="otpform_inner-ref">Ref. DEFG</span>
                <div className="form-group">
                  <span className="group-name">เบอร์โทรศัพท์</span>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control otptext"
                    value={this.state.otp_number}
                    onChange={(e) => {
                      let replaceInput = e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (replaceInput !== "") {
                        this.setState({
                          otp_number: replaceInput,
                          disableOTPButton: false,
                        });
                      } else {
                        this.setState({
                          otp_number: replaceInput,
                          disableOTPButton: true,
                          isErrorCode: "",
                        });
                      }
                    }}
                  />
                </div>
                <span className="otpform_inner-error">{msg_error}</span>
                <button
                  className="btn checkotp"
                  onClick={this.verifyOTP}
                  disabled={disableOTPButton}
                >
                  ยืนยัน OTP
                </button>
              </div>

              <div className="otpform_line"></div>

              <div className="otpform_repeat">
                <button onClick={this.newRequestAgain}>ขอรหัส OTP ใหม่</button>
                <span>
                  หมายเหตุ หากไม่ได้รับรหัสภายใน 5 นาที <br />
                  กรุณาคลิกเพื่อให้ระบบส่งรหัสให้อีกครั้ง
                </span>
              </div>
            </div>
          </section>
          <footer></footer>
        </body>
      </Fragment>
    );
  }
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(OTPVerify);
// export default OTPVerify;
