import axios from "axios";
// const BASE_URL = "/ws/";   //production
// const BASE_URL = "/"; //dev
// 192.168.251.10
const BASE_URL = 'https://easymoneydev.com/ws/';
const instanceMain = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true
  }
});
class Provider {
  api(options) {
    return new Promise(async function(resolve, reject) {
      let { type, url, data, headers } = options;
      let dataRes;
      switch (type) {
        case "GET":
          dataRes = await instanceMain.get(url, headers);
          break;
        case "SHOW":
          dataRes = await instanceMain.get(url);
          break;
        case "POST":
          dataRes = await instanceMain.post(url, data, headers);
          break;
        case "PUT":
          dataRes = await instanceMain.put(url, data, headers);
          break;
        case "DELETE":
          dataRes = await instanceMain.delete(url);
          break;
        default:
          break;
      }
      resolve(dataRes.data);
    });
  }
}

export default new Provider();
