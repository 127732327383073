import React, { Component, Fragment } from "react";
// import axios from "axios";
// import { Button } from "react-bootstrap";
import Helper from "../Global/Helper";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import ModalAlert from "../Components/ModalAlert";
import liffHelper from "../utils/liffHelper";
import logo_head from "./images/logo.png";
import appqr from "./images/appqr.png";
import Service from "../services/home";
import exit from "./images/exit.png";
const liff = window.liff;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCode: false,
      openPolicy: false,
      isRegistered: false,
      checkAcceptPolicy: false,
      openModalAlert: false,
      submit_btn: true,
      code: "",
      first_name: "",
      last_name: "",
      mobile: "",
      isErrorCode: "",
      disableButton: true,
      msg: "",
      master_campaign_id: "14",
      policy_name: "2tm_policy",
      profile: {
        userId: "",
        // userId: "U4fb7c99525626afb9cabde017e810290",
      },
    };
  }

  componentDidMount() {
    localStorage.clear();
    Helper.resetToTop();
    liffHelper.getProfile().then((profile) => {
      this.setState({ profile }, () => {
        // localStorage.setItem("uid", profile.userId);
        this.actionStart();
      });
    });
    // this.actionStart();
  }
  // ---------------------------SERVICE--------------------
  // LOAD JWT
  async actionStart() {
    try {
      const { data, status } = await Service.loadStartJWT();
      if (status === "Success") {
        if (data !== null) {
          this.actionLoginLineliff(data);
        } else {
          console.log("status   ==== jwt === null");
        }
      } else {
        console.log("loadStartJWT NOT SUCCESS");
      }
    } catch (error) {
      console.log("DISCONNECT API");
    }
  }
  async actionLoginLineliff(jwt) {
    try {
      const { data, status } = await Service.actionLoginLineliff(
        jwt,
        this.state.profile.userId,
        this.state.master_campaign_id
      );
      if (status === "Success") {
        if (data.status === "no_played") {
          localStorage.setItem("jwt", data.jwt);
          this.setState({
            openCode: true,
          });
          console.log("ยังไม่เล่น");
        } else if (data.status === "played") {
          localStorage.setItem("jwt", data.jwt);
          this.setState({
            isRegistered: true,
          });
          console.log("เล่นแล้ว");
        }
      } else {
        console.log("LOGIN NOT SUCCESS");
        liff.closeWindow();
      }
    } catch (error) {
      console.log("DISCONNECT API");
    }
  }

  // checkPromotionCode
  async checkPromotionCode() {
    let jwt = localStorage.getItem("jwt");
    const { code, master_campaign_id } = this.state;
    try {
      const { status, msg } = await Service.checkPromotionCode(
        jwt,
        code,
        master_campaign_id
      );
      if (status === "Success") {
        this.setState({
          openCode: false,
        });
      } else {
        this.setState({
          isErrorCode: "errorcode",
          msg: msg,
        });
      }
    } catch (error) {
      console.log("DISCONNECT API");
    }
  }
  // savePolicyLineliff
  async savePolicyLineliff() {
    let jwt = localStorage.getItem("jwt");
    const { policy_name } = this.state;
    try {
      const { status, msg } = await Service.savePolicyLineliff(
        jwt,
        policy_name
      );
      if (status === "Success") {
        this.setState({
          openPolicy: false,
          checkAcceptPolicy: true,
        });
      } else {
        this.setState({
          openPolicy: false,
          checkAcceptPolicy: true,
        });
        //  this.setState({
        //   openPolicy: true,
        // });
      }
    } catch (error) {
      console.log("DISCONNECT API");
    }
  }

  // ---------------------------END SERVICE--------------------
  AcceptPolicy = () => {
    this.setState({
      openPolicy: false,
      checkAcceptPolicy: true,
    });
  };
  openPolicy = () => {
    this.setState({
      openPolicy: true,
    });
  };
  handleClick = () => {
    const { code } = this.state;
    this.checkPromotionCode(code);
  };

  submitData = () => {
    console.log("submitData");
    const { history } = this.props;
    const { first_name, last_name, mobile, code } = this.state;
    let customer = {
      first_name,
      last_name,
      mobile,
      code,
    };
    localStorage.setItem("customer", JSON.stringify(customer));
    history.push("/otp");
  };
  render() {
    const {
      openPolicy,
      openCode,
      openModalAlert,
      textAlert,
      textAlertSub,
      first_name,
      last_name,
      mobile,
      code,
      submit_btn,
      isErrorCode,
      disableButton,
      profile,
      msg,
      checkAcceptPolicy,
      isRegistered,
    } = this.state;
    return (
      <Fragment>
        <body>
          <header>
            <img src={logo_head} alt="" />
          </header>
          <section>
            {isRegistered === true ? (
              <div className="samllpop">
                <div className="samllpop_pop">
                  <img
                    src={exit}
                    onClick={() => {
                      // console.log("onExit");
                       liff.closeWindow();
                    }}
                    alt=""
                    className="exit"
                  />
                  <h2 className="font55">ขออภัยค่ะ</h2>
                  <h3 className="font45">คุณใช้สิทธิลงทะเบียนไปแล้ว</h3>
                </div>
              </div>
            ) : null}

            <div className="dataform">
              <div className="form-group">
                <span className="group-name">ชื่อ</span>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  onFocus={() => {
                    if (checkAcceptPolicy === false) {
                      this.setState({
                        openPolicy: true,
                      });
                    }
                  }}
                  value={this.state.first_name}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()+></?=\;#]/g,
                      ""
                    );
                    if (
                      replaceInput !== "" &&
                      last_name.length > 0 &&
                      mobile.length === 10
                    ) {
                      this.setState({
                        first_name: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        first_name: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <span className="group-name">นามสกุล</span>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control"
                  onFocus={() => {
                    if (checkAcceptPolicy === false) {
                      this.setState({
                        openPolicy: true,
                      });
                    }
                  }}
                  value={this.state.last_name}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()+></?=\;#]/g,
                      ""
                    );
                    if (
                      replaceInput !== "" &&
                      first_name.length > 0 &&
                      mobile.length === 10
                    ) {
                      this.setState({
                        last_name: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        last_name: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <span className="group-name">เบอร์โทรศัพท์</span>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  maxLength="10"
                  className="form-control"
                  onFocus={() => {
                    if (checkAcceptPolicy === false) {
                      this.setState({
                        openPolicy: true,
                      });
                    }
                  }}
                  value={this.state.mobile}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    if (
                      last_name.length > 0 &&
                      first_name.length > 0 &&
                      replaceInput.length === 10
                    ) {
                      this.setState({
                        mobile: replaceInput,
                        submit_btn: false,
                      });
                    } else {
                      this.setState({
                        mobile: replaceInput,
                        submit_btn: true,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </section>
          <div className="appqr">
            <img
              src={appqr}
              alt=""
              onClick={() => {
                window.open(
                  "https://easymoney.co.th/redirect.php?app=easysmart",
                  ""
                );
              }}
            />
          </div>

          <div className="btncover">
            <button
              className="btn senddata"
              disabled={submit_btn}
              onClick={() => {
                this.submitData();
              }}
            >
              ยืนยันข้อมูล
            </button>
          </div>
          {/* <p>Line UID: {profile.userId}</p> */}
          <footer></footer>
          {openPolicy === true ? (
            <div className="trempop trempop_1">
              <div className="trempop_pop">
                <h2 className="font45">ข้อกำหนดและเงื่อนไข</h2>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อีซี่มันนี่
                  จะขอใช้ข้อมูลที่ระบุไว้ เพื่อวัตถุประสงค์ในการป้องกัน
                  การใช้งานที่ไม่เหมาะสม รวมไปถึงการใช้บริการ การพัฒนานำเสนอ
                  โปรโมชั่น การประชาสัมพันธ์ และการปรับปรุงการบริการเป็นการเก็บ
                  รวบรวมการใช้ และเปิดเผยข้อมูลส่วนตัวสำหรับข้อมูลต่างๆ ได้แก่
                  ชื่อ, นามสกุล, เบอร์โทรศัพท์เพื่อตรวจสอบ และยืนยันตัวตนในการ
                  สมัคร และ/หรือใช้บริการ และ/หรือเป็นหลักฐานในทางกฎหมายสำหรับ
                  ข้อต่อสู้ทางกฎหมาย หรือเป็นการดำเนินการตามที่กฎหมายกำหนด
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ
                  จะไม่จัดสรรหรือขายข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกอื่นเพื่อวัตถุประสงค์ในการนำไปใช้โดยอิสระนอกจากนี้บริษัทฯ
                  จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่องค์กรอื่นใด
                  เว้นแต่การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาตหรือในกรณีที่บริษัทฯ
                  มีหน้าที่ตามกฎหมายกำหนดให้ต้องเปิดเผย,
                  หรือเป็นการเปิดเผยตามคำสั่งโดยชอบด้วยกฎหมายของศาลหรือพนักงานเจ้าหน้าที่ผู้มีอำนาจ
                  <br />
                  <br />
                  หมายเหตุ
                  <br />
                  1. เมื่อลงทะเบียนสำเร็จ
                  ระบบจะยืนยันให้ทราบทันทีว่าท่านได้ลงทะเบียนเรียบร้อยแล้ว
                  <br />
                  2. ท่านจะได้รับคูปองในไลน์ภายหลังจากการลงทะเบียนเสร็จสิ้น
                  <br />
                  3. กรณีมีปัญหาในการลงทะเบียน หรือสอบถามข้อมูลเพิ่มเติม ติดต่อ
                  Call Center โทร.02-113-1123
                </p>
                <button
                  class="btn accepttrem_1 sub"
                  onClick={() => {
                    this.savePolicyLineliff();
                  }}
                >
                  ยอมรับข้อตกลง
                </button>
              </div>
            </div>
          ) : null}

          {/* {openCountDown === true ? (
            <div className="samllpop">
              <div className="samllpop_pop">
                <h2 className="font55">ขอบคุณที่ใช้บริการ</h2>
                <h3 className="font45">โรงรับจำนำอีซี่มันนี่</h3>
                <span className="font45">
                  ระบบจำนำท่านกลับสู่หน้าแรกอัตโนมัติ
                </span>
              </div>
            </div>
          ) : null} */}
          {openCode === true ? (
            <div className="codepop ">
              <div className={`codepop_pop ${isErrorCode}`}>
                <div className="codepop_pop-inner">
                  <span className="font45">กรุณากรอกโค้ด</span>
                  <input
                    type="text"
                    className="font45 codetext"
                    name=""
                    id=""
                    value={this.state.code}
                    onChange={(e) => {
                      let replaceInput = e.currentTarget.value.replace(
                        /[!$%^*.()+></?=\;#]/g,
                        ""
                      );
                      if (replaceInput != "") {
                        this.setState({
                          code: replaceInput,
                          isErrorCode: "",
                          disableButton: false,
                        });
                      } else {
                        this.setState({
                          code: replaceInput,
                          disableButton: true,
                        });
                      }
                    }}
                  />
                  <span className="errornote">{msg}</span>
                  <button
                    className="btn checkcode"
                    onClick={this.handleClick}
                    disabled={disableButton}
                  >
                    ตกลง
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </body>
        <ModalAlert
          hideReg
          open={openModalAlert}
          textAlert={textAlert}
          textAlertSub={textAlertSub}
          textAlertButton={"ย้อนกลับ"}
          // กรุณากรอกรหัสใหม่
          onSubmit={() => {
            // this.sendMessageNo();
            this.setState({ openModalAlert: false });
          }}
          onCancel={() => this.setState({ openModalAlert: false })}
        />
      </Fragment>
    );
  }
}
export default Home;
