import React from "react";

import { Row, Column } from "./Other/grid.jsx";
import ModalAlert from "./Other/ModalAlert.jsx";
import "../pages/styles.css";
export default props => {
  return (
    <ModalAlert open={props.open}>
      <Column width="100%" style={{}}>
        <div>
          <p className="text-modal">{props.textAlert}</p>
          <p className="text-modal-sub">{props.textAlertSub}</p>
        </div>
      </Column>
      <Row className="text-center">
        <Column width="95%">
          <button className="button-comfirm-modal" onClick={props.onSubmit}>
            {props.textAlertButton}
          </button>
        </Column>
      </Row>
    </ModalAlert>
  );
};
